import React from 'react';
import { connect } from 'react-redux';
import MissionGroupAll from './MissionGroupAll';
import MissionGroupMost from './MissionGroupMost';
import { filterMostGroups } from '../../redux/mission/missionActions';
import { useNavigate, useParams } from 'react-router';

const MissionGroupWizard = ({ allGroups, dispatchFilterMostGroups }) => {
  let navigate = useNavigate();
  const { step } = useParams();

  const handleNext = () => {
    dispatchFilterMostGroups(allGroups);
    navigate('/mission/groups/most');
  };

  const handleFinish = () => {
    navigate('/mission/view');
  };

  return (
    <div className="col-md-12">
      {step === 'all' ? (
        <MissionGroupAll onNext={handleNext} />
      ) : (
        <MissionGroupMost onFinish={handleFinish} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allGroups: state.mission.allGroups,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchFilterMostGroups: (groups) => dispatch(filterMostGroups(groups)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionGroupWizard);
