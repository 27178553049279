import React from 'react';
import { Route, Routes } from 'react-router';
import NotFoundPage from '../../pages/NotFoundPage';
import MissionVerbWizard from './MissionVerbWizard';
import MissionGroupWizard from './MissionGroupWizard';
import MissionView from './MissionView';
import MissionValues from './MissionValues';

const MissionRouter = () => {
  return (
    <Routes>
      <Route path="/values" element={<MissionValues />} />
      <Route path="/verbs/:step" element={<MissionVerbWizard />} />
      <Route path="/groups/:step" element={<MissionGroupWizard />} />
      <Route path="/view" element={<MissionView />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default MissionRouter;
