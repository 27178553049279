import React from 'react';
import { connect } from "react-redux";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton, TwitterIcon, TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { absFrontendUrl } from "../../utils/paramsUtils";

const MissionShare = ({
                          id, name, missionText,
                          buttonSize, borderRadius
                      }) => {
    return (
        <div className="col-md-12">
            <WhatsappShareButton
                url={`${absFrontendUrl}/site/external?id=${id}`}
                title={name}
            >
                <WhatsappIcon size={buttonSize} borderRadius={borderRadius}/>
            </WhatsappShareButton>
            &nbsp;
            <TelegramShareButton
                url={`${absFrontendUrl}/site/external?id=${id}`}
                title={name}
            >
                <TelegramIcon size={buttonSize} borderRadius={borderRadius}/>
            </TelegramShareButton>
            &nbsp;
            <FacebookShareButton
                url={`${absFrontendUrl}/site/external?id=${id}`}
                quote={name}
            >
                <FacebookIcon size={buttonSize} borderRadius={borderRadius}/>
            </FacebookShareButton>
            &nbsp;
            <TwitterShareButton
                url={`${absFrontendUrl}/site/external?id=${id}`}
                quote={name}
            >
                <TwitterIcon size={buttonSize} borderRadius={borderRadius}/>
            </TwitterShareButton>
            &nbsp;
            <LinkedinShareButton
                url={`${absFrontendUrl}/site/external?id=${id}`}
                title={name}
            >
                <LinkedinIcon size={buttonSize} borderRadius={borderRadius}/>
            </LinkedinShareButton>
            &nbsp;
            <EmailShareButton
                url={`${absFrontendUrl}/site/external?id=${id}`}
                subject={name}
                body={missionText}
            >
                <EmailIcon size={buttonSize} borderRadius={borderRadius}/>
            </EmailShareButton>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        answers: state.mission.answers,
        mostVerbs: state.mission.mostVerbs,
        mostGroups: state.mission.mostGroups
    };
};

export default connect(mapStateToProps)(MissionShare);
