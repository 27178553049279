import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, FormCheck } from 'react-bootstrap';
import { connect } from 'react-redux';
import slug from 'slug';

import {
  addToMostVerbs,
  removeFromMostVerbs,
} from '../../redux/mission/missionActions';

class MissionVerbMost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
    };
  }

  checkChanged = (e) => {
    const { dispatchAddToMostVerbs, dispatchRemoveFromMostVerbs } = this.props;
    const verb = e.target.getAttribute('verb');
    if (e.target.checked) {
      dispatchAddToMostVerbs(verb);
    } else {
      dispatchRemoveFromMostVerbs(verb);
    }
    this.setState({
      ...this.state,
      canValidate: false,
    });
  };

  validateSelection = () => {
    if (this.props.mostVerbs.length === 3) {
      this.props.onFinish();
    } else {
      this.setState({
        ...this.state,
        canValidate: true,
      });
    }
  };

  render() {
    const { allVerbs, mostVerbs } = this.props;

    return (
      <div id="mission-most-verbs">
        <p>
          <Trans>Now select 3 actions you like the most:</Trans>
        </p>
        <div className="text-center">
          <div className="form-group">
            {allVerbs.map((verb, index) => (
              <div key={`k-${index}`} className="row justify-content-center">
                <div className="col-auto">
                  <h5>
                    <FormCheck
                      custom
                      id={`checkbox-${slug(verb, { lower: true })}`}
                      type="checkbox"
                      label={verb}
                      verb={verb}
                      checked={mostVerbs.includes(verb)}
                      onChange={this.checkChanged}
                    />
                  </h5>
                </div>
              </div>
            ))}
          </div>
          {this.state.canValidate && mostVerbs.length !== 3 && (
            <div className="form-group">
              <div className="alert alert-danger">
                <Trans>Select 3 actions!</Trans>
              </div>
            </div>
          )}
          <div className="form-group">
            <Button
              id="mission-verb-submit"
              className="btn btn-lg btn-primary"
              onClick={this.validateSelection}
            >
              <Trans>Next</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.user.language,
  allVerbs: state.mission.allVerbs,
  mostVerbs: state.mission.mostVerbs,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddToMostVerbs: (newVerb) => dispatch(addToMostVerbs(newVerb)),
  dispatchRemoveFromMostVerbs: (newVerb) =>
    dispatch(removeFromMostVerbs(newVerb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionVerbMost);
