import { t } from '@lingui/macro';

export const buildVerbsText = (mostVerbs) => mostVerbs.join(', ');
export const buildGroupsTex = (mostGroups) => mostGroups.join(', ');

export const buildMission = (values, mostVerbs, mostGroups) => {
  const verbsText = buildVerbsText(mostVerbs);
  const groupsText = buildGroupsTex(mostGroups);

  return `${t`My mission is`} ${t`to`} ${verbsText} ${t`using`} ${values} ${t`in service of`} ${groupsText}`;
};
