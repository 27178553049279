import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, FormCheck } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  addToAllGroups,
  removeFromAllGroups,
} from '../../redux/mission/missionActions';
import slug from 'slug';
import { MissionGroups } from '../../components/MissionData';

const MissionGroupAll = ({
  allGroups,
  dispatchAddToAllGroups,
  dispatchRemoveFromAllGroups,
  onNext,
}) => {
  const groups = MissionGroups();
  const [canValidate, setCanValidate] = useState(false);

  const checkboxChanged = (e) => {
    const group = e.target.getAttribute('group');
    if (e.target.checked) {
      dispatchAddToAllGroups(group);
    } else {
      dispatchRemoveFromAllGroups(group);
    }
    setCanValidate(false);
  };

  const validateSelection = () => {
    if (allGroups.length > 3) {
      onNext();
    } else {
      setCanValidate(true);
    }
  };

  return (
    <div id="mission-all-groups">
      <p>
        <Trans>Select all groups you like to serve:</Trans>
      </p>
      <div className="text-center">
        <div className="form-group">
          {groups.map((group, index) => (
            <div key={`k-${index}`} className="row justify-content-center">
              <div className="col-auto">
                <h5>
                  <FormCheck
                    custom
                    id={`checkbox-${slug(group, { lower: true })}`}
                    type="checkbox"
                    label={group}
                    group={group}
                    checked={allGroups.includes(group)}
                    onChange={checkboxChanged}
                  />
                </h5>
              </div>
            </div>
          ))}
        </div>
        {canValidate && allGroups.length < 3 && (
          <div className="form-group">
            <div className="alert alert-danger">
              <Trans>Select at least 3 groups!</Trans>
            </div>
          </div>
        )}
        <div className="form-group">
          <Button
            id="mission-group-submit"
            className="btn btn-lg btn-primary"
            onClick={validateSelection}
          >
            <Trans>Next</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.user.language,
  allGroups: state.mission.allGroups,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddToAllGroups: (newGroup) => dispatch(addToAllGroups(newGroup)),
  dispatchRemoveFromAllGroups: (newGroup) =>
    dispatch(removeFromAllGroups(newGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionGroupAll);
