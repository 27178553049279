import { i18n } from '@lingui/core';
import { messages as enMessages } from '../locales/en/messages.js';
import { messages as esMessages } from '../locales/es/messages.js';

export const locales = [
  { key: 'es', name: 'Español' },
  { key: 'en', name: 'English' },
];

i18n.load('en', enMessages);
i18n.load('es', esMessages);

export const setLocales = (language) => {
  i18n.activate(language);
};
i18n.activate('es');
