import { Route, Routes } from 'react-router';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import MissionRouter from './pages/mission/MissionRouter';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import { setLocales } from './components/Locales';
import { setLanguage } from './redux/user/userActions';
import { connect } from 'react-redux';

function App({ language }) {
  setLocales(language);
  setLanguage(language);

  return (
    <div style={{ overflow: 'auto', margin: '0px 16px' }}>
      <Header />
      <Container fluid={true} className="text-center jumbotron my-5">
        <Routes>
          <Route path="/" element={<HomePage />} exact={true} />
          <Route path="/mission/*" element={<MissionRouter />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.user.language,
});

export default connect(mapStateToProps)(App);
