import React from 'react';
import { connect } from 'react-redux';
import { setLanguage } from '../redux/user/userActions';
import { Button } from 'react-bootstrap';
import { locales } from './Locales';

const buildButton = ({ key, name }, language, dispatchSetLanguage) => (
  <Button
    key={key}
    data-language={key}
    onClick={() => dispatchSetLanguage(key)}
    variant={language === key ? 'secondary' : 'outline-secondary'}
    className="my-2 mx-1"
  >
    {name}
  </Button>
);

const LanguageSetter = ({ language, dispatchSetLanguage }) => (
  <div className="mt-auto text-center">
    {locales.map((locale) =>
      buildButton(locale, language, dispatchSetLanguage)
    )}
  </div>
);

const mapStateToProps = (state) => ({
  language: state.user.language,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetLanguage: (language) => dispatch(setLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSetter);
