import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, FormCheck } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  addToMostGroups,
  removeFromMostGroups,
} from '../../redux/mission/missionActions';
import slug from 'slug';

const MissionGroupMost = ({
  allGroups,
  mostGroups,
  dispatchAddToMostGroups,
  dispatchRemoveFromMostGroups,
  onFinish,
}) => {
  const [canValidate, setCanValidate] = useState(false);

  const checkChanged = (e) => {
    const group = e.target.getAttribute('group');
    if (e.target.checked) {
      dispatchAddToMostGroups(group);
    } else {
      dispatchRemoveFromMostGroups(group);
    }
    setCanValidate(false);
  };

  const validateSelection = () => {
    if (mostGroups.length === 3) {
      onFinish();
    } else {
      setCanValidate(true);
    }
  };

  return (
    <div id="mission-most-groups">
      <p>
        <Trans>Now select 3 groups you like the most:</Trans>
      </p>
      <div className="text-center">
        <div className="form-group">
          {allGroups.map((group, index) => (
            <div key={`k-${index}`} className="row justify-content-center">
              <div className="col-auto">
                <h5>
                  <FormCheck
                    custom
                    id={`checkbox-${slug(group, { lower: true })}`}
                    type="checkbox"
                    label={group}
                    group={group}
                    checked={mostGroups.includes(group)}
                    onChange={checkChanged}
                  />
                </h5>
              </div>
            </div>
          ))}
        </div>
        {canValidate && mostGroups.length !== 3 && (
          <div className="form-group">
            <div className="alert alert-danger">
              <Trans>Select 3 groups!</Trans>
            </div>
          </div>
        )}
        <div className="form-group">
          <Button
            id="mission-group-submit"
            className="btn btn-lg btn-primary"
            onClick={validateSelection}
          >
            <Trans>Next</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.user.language,
  allGroups: state.mission.allGroups,
  mostGroups: state.mission.mostGroups,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddToMostGroups: (newGroup) => dispatch(addToMostGroups(newGroup)),
  dispatchRemoveFromMostGroups: (newGroup) =>
    dispatch(removeFromMostGroups(newGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionGroupMost);
