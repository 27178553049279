import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import MissionShare from './MissionShare';
import {
  buildGroupsTex,
  buildMission,
  buildVerbsText,
} from '../../utils/missionUtils';
import useClipboard from 'react-use-clipboard';
import { Button } from 'react-bootstrap';
import {
  setAllGroups,
  setAllVerbs,
  setMostGroups,
  setMostVerbs,
  setValues,
} from '../../redux/mission/missionActions';
import { useNavigate } from 'react-router';

const MissionView = ({
  values,
  dispatchSetValues,
  mostVerbs,
  dispatchSetMostVerbs,
  dispatchSetAllVerbs,
  mostGroups,
  dispatchSetMostGroups,
  dispatchSetAllGroups,
}) => {
  let navigate = useNavigate();
  const verbsText = buildVerbsText(mostVerbs);
  const groupsText = buildGroupsTex(mostGroups);
  const missionText = buildMission(values, mostVerbs, mostGroups);

  const [isCopied, setCopied] = useClipboard(missionText);

  const startOver = () => {
    dispatchSetValues('');
    dispatchSetMostVerbs([]);
    dispatchSetAllVerbs([]);
    dispatchSetMostGroups([]);
    dispatchSetAllGroups([]);
    navigate('/mission/values');
  };

  return (
    <div id="mission-result-page" className="col-md-12">
      <div id="mission-result" className="jumbotron text-center">
        <h2>
          <p>
            <Trans>Your mission is</Trans>
          </p>
          <p>
            <Trans>to</Trans> <span id="result-verbs">{verbsText}</span>
          </p>
          <p>
            <Trans>using</Trans>
          </p>
          <p id="result-values">{values}</p>
          <p>
            <Trans>in service of</Trans>
          </p>
          <p id="result-groups">{groupsText}</p>
        </h2>
      </div>
      <div className="my-4">
        <MissionShare
          missionText={missionText}
          buttonSize={30}
          borderRadius={12}
        />
        <Button
          className="my-3"
          variant={isCopied ? 'secondary' : 'primary'}
          type="button"
          onClick={setCopied}
        >
          {isCopied ? <Trans>Copied!</Trans> : <Trans>Copy</Trans>}
        </Button>
        <br />
        <Button variant="outline-secondary" onClick={startOver}>
          <Trans>Start over</Trans>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  values: state.mission.values,
  mostVerbs: state.mission.mostVerbs,
  mostGroups: state.mission.mostGroups,
  language: state.user.language,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetValues: (values) => dispatch(setValues(values)),
  dispatchSetMostVerbs: (verbs) => dispatch(setMostVerbs(verbs)),
  dispatchSetMostGroups: (verbs) => dispatch(setMostGroups(verbs)),
  dispatchSetAllVerbs: (verbs) => dispatch(setAllVerbs(verbs)),
  dispatchSetAllGroups: (verbs) => dispatch(setAllGroups(verbs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionView);
