import React, { useState } from 'react';
import { connect } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { setValues } from '../../redux/mission/missionActions';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const schema = Yup.object().shape({
  answer: Yup.string()
    .max(300, t`Your answer is too long!`)
    .required(t`Your answer is required!`),
});

const MissionValues = ({ values, dispatchSetValues }) => {
  const question = t`What values are behind your most admired persons?`;
  const answerRef = React.createRef();

  const [canValidate, setCanValidate] = useState(false);

  const onAnswer = (e) => {
    setCanValidate(true);
    dispatchSetValues(e.target.value);
  };

  const isAnswerValid = (newAnswer) =>
    schema.isValidSync({ answer: newAnswer });

  const errorMessage = (newAnswer) => {
    try {
      schema.validateSync({ answer: newAnswer });
    } catch (e) {
      return e.errors[0];
    }
  };

  return (
    <div
      id={`question`}
      className="question text-center col-md-8 offset-md-2"
      style={{ padding: 0 }}
    >
      <label className="control-label">{question}</label>
      <textarea
        ref={answerRef}
        className="question-answer form-control mt-2"
        rows="3"
        maxLength={300}
        onChange={onAnswer}
        value={values}
      ></textarea>
      {canValidate && !isAnswerValid(values) && (
        <div className="alert alert-danger">{errorMessage(values)}</div>
      )}
      <div className="form-group my-2">
        <Link className="btn btn-primary btn-lg" to="/mission/verbs/all">
          <Trans>Next</Trans>
        </Link>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  values: state.mission.values,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetValues: (values) => dispatch(setValues(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionValues);
