import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userReducer } from './user/userReducer';
import missionReducer from './mission/missionReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'mission'],
  transforms: [],
};

const rootReducer = combineReducers({
  user: userReducer || (() => null),
  mission: missionReducer || (() => null),
});

export default persistReducer(persistConfig, rootReducer);
