import { MissionActionTypes } from './missionActions';

const INITIAL_STATE = {
  values: '',
  allVerbs: [],
  mostVerbs: [],
  allGroups: [],
  mostGroups: [],
};

const missionReducer = (state, action) => {
  state = state || INITIAL_STATE;
  let auxArray, index;
  switch (action.type) {
    case MissionActionTypes.SET_VALUES:
      return {
        ...state,
        values: action.values,
      };
    case MissionActionTypes.SET_ALL_VERBS:
      return {
        ...state,
        allVerbs: action.verbs,
      };
    case MissionActionTypes.ADD_TO_ALL_VERBS:
      auxArray = state.allVerbs.slice();
      if (!auxArray.includes(action.verb)) {
        auxArray.push(action.verb);
      }
      return {
        ...state,
        allVerbs: auxArray,
      };
    case MissionActionTypes.REMOVE_FROM_ALL_VERBS:
      auxArray = state.allVerbs.slice();
      index = auxArray.indexOf(action.verb);
      if (index > -1) {
        auxArray.splice(index, 1);
      }
      return {
        ...state,
        allVerbs: auxArray,
      };
    case MissionActionTypes.SET_MOST_VERBS:
      return {
        ...state,
        mostVerbs: action.verbs,
      };
    case MissionActionTypes.FILTER_MOST_VERBS:
      auxArray = state.mostVerbs.filter((mostVerb) =>
        action.verbs.includes(mostVerb)
      );
      return {
        ...state,
        mostVerbs: auxArray,
      };
    case MissionActionTypes.ADD_TO_MOST_VERBS:
      auxArray = state.mostVerbs.slice();
      if (!auxArray.includes(action.verb)) {
        auxArray.push(action.verb);
      }
      return {
        ...state,
        mostVerbs: auxArray,
      };
    case MissionActionTypes.REMOVE_FROM_MOST_VERBS:
      auxArray = state.mostVerbs.slice();
      index = auxArray.indexOf(action.verb);
      if (index > -1) {
        auxArray.splice(index, 1);
      }
      return {
        ...state,
        mostVerbs: auxArray,
      };
    case MissionActionTypes.SET_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.groups,
      };
    case MissionActionTypes.ADD_TO_ALL_GROUPS:
      auxArray = state.allGroups.slice();
      if (!auxArray.includes(action.group)) {
        auxArray.push(action.group);
      }
      return {
        ...state,
        allGroups: auxArray,
      };
    case MissionActionTypes.REMOVE_FROM_ALL_GROUPS:
      auxArray = state.allGroups.slice();
      index = auxArray.indexOf(action.group);
      if (index > -1) {
        auxArray.splice(index, 1);
      }
      return {
        ...state,
        allGroups: auxArray,
      };
    case MissionActionTypes.SET_MOST_GROUPS:
      return {
        ...state,
        mostGroups: action.groups,
      };
    case MissionActionTypes.FILTER_MOST_GROUPS:
      auxArray = state.mostGroups.filter((mostGroup) =>
        action.groups.includes(mostGroup)
      );
      return {
        ...state,
        mostGroups: auxArray,
      };
    case MissionActionTypes.ADD_TO_MOST_GROUPS:
      auxArray = state.mostGroups.slice();
      if (!auxArray.includes(action.group)) {
        auxArray.push(action.group);
      }
      return {
        ...state,
        mostGroups: auxArray,
      };
    case MissionActionTypes.REMOVE_FROM_MOST_GROUPS:
      auxArray = state.mostGroups.slice();
      index = auxArray.indexOf(action.group);
      if (index > -1) {
        auxArray.splice(index, 1);
      }
      return {
        ...state,
        mostGroups: auxArray,
      };
    case MissionActionTypes.SET_SAVED:
      return {
        ...state,
        saved: action.status,
      };
    case MissionActionTypes.SET_LIST:
      return {
        ...state,
        list: action.list,
      };
    case MissionActionTypes.SET_MISSION:
      return {
        ...state,
        allVerbs: [],
        allGroups: [],
        ...action.mission,
      };
    case MissionActionTypes.CLEAN:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default missionReducer;
