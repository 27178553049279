import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, FormCheck } from 'react-bootstrap';
import { connect } from 'react-redux';
import slug from 'slug';

import {
  addToAllVerbs,
  removeFromAllVerbs,
} from '../../redux/mission/missionActions';
import { MissionVerbs } from '../../components/MissionData';

class MissionVerbAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
    };
  }

  checkChanged = (e) => {
    const verb = e.target.getAttribute('verb');
    if (e.target.checked) {
      this.props.addToAllVerbs(verb);
    } else {
      this.props.removeFromAllVerbs(verb);
    }
    this.setState({
      ...this.state,
      canValidate: false,
    });
  };

  validateSelection = () => {
    if (this.props.allVerbs.length > 3) {
      this.props.onNext();
    } else {
      this.setState({
        ...this.state,
        canValidate: true,
      });
    }
  };

  render() {
    const { language, allVerbs } = this.props;
    const verbs = MissionVerbs()[language];
    return (
      <div id="mission-all-verbs">
        <p>
          <Trans>Select all actions you like to do:</Trans>
        </p>
        <div className="text-center">
          <div className="form-group">
            {verbs.map((verb, index) => (
              <div key={`k-${index}`} className="row justify-content-center">
                <div className="col-auto">
                  <h5>
                    <FormCheck
                      custom
                      id={`checkbox-${slug(verb, { lower: true })}`}
                      type="checkbox"
                      label={verb}
                      verb={verb}
                      checked={allVerbs.includes(verb)}
                      onChange={this.checkChanged}
                    />
                  </h5>
                </div>
              </div>
            ))}
          </div>
          {this.state.canValidate && allVerbs.length < 3 && (
            <div className="form-group">
              <div className="alert alert-danger">
                <Trans>Select at least 3 actions!</Trans>
              </div>
            </div>
          )}
          <div className="form-group">
            <Button
              id="mission-verb-submit"
              className="btn btn-lg btn-primary"
              onClick={this.validateSelection}
            >
              <Trans>Next</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.user.language,
  allVerbs: state.mission.allVerbs,
});

const mapDispatchToProps = (dispatch) => ({
  addToAllVerbs: (newVerb) => dispatch(addToAllVerbs(newVerb)),
  removeFromAllVerbs: (newVerb) => dispatch(removeFromAllVerbs(newVerb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionVerbAll);
