import { UserActionTypes, setLanguage } from './userActions';
import { locales } from '../../components/Locales';

const INITIAL_STATE = {
  language: window ? (window.defaults ? window.defaults.language : 'es') : 'es',
};

const languageImplemented = (language) => {
  for (let i = 0; i < locales.length; i++) {
    if (locales[i].key === language) {
      return true;
    }
  }
  return false;
};

export const userReducer = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case UserActionTypes.SET_LANGUAGE:
      if (languageImplemented(action.language)) {
        setLanguage(action.language);
        return {
          ...state,
          language: action.language,
        };
      }
      return state;
    default:
      return state;
  }
};
