import React from 'react';
import Button from 'react-bootstrap/Button';
import { Trans } from '@lingui/macro';
import { Container } from 'react-bootstrap';

const NotFoundPage = () => (
  <div>
    <Container fluid={true} className="text-center jumbotron">
      <h2>
        <Trans>Not Found</Trans>
      </h2>
      <Button variant="default" type="button" href="/">
        <Trans>Go to home</Trans>
      </Button>
    </Container>
  </div>
);

export default NotFoundPage;
