import { t } from '@lingui/macro';

export const MissionVerbs = () => ({
  es: [
    'Abrazar',
    'Abrir',
    'Acariciar',
    'Aconsejar',
    'Adoptar',
    'Adquirir',
    'Afectar',
    'Afirmar',
    'Agruparse',
    'Ahorrar',
    'Alabar',
    'Alentar',
    'Aliviar',
    'Amar',
    'Amplificar',
    'Apoyar',
    'Apreciar',
    'Ascender',
    'Asociarse',
    'Auspiciar',
    'Avanzar',
    'Aventurarse',
    'Brillar',
    'Cantar',
    'Causar',
    'Cobrar',
    'Coleccionar',
    'Comandar',
    'Combinar',
    'Comerciar',
    'Compartir',
    'Competir',
    'Completar',
    'Componer',
    'Comunicar',
    'Concebir',
    'Conectar',
    'Confiar',
    'Confirmar',
    'Congratular',
    'Conocer',
    'Considerar',
    'Construir',
    'Contactar',
    'Continuar',
    'Contratar',
    'Convocar',
    'Crear',
    'Creer',
    'Curar',
    'Dar',
    'Decidir',
    'Defender',
    'Demostrar',
    'Descubrir',
    'Dibujar',
    'Dirigir',
    'Discutir',
    'Distribuir',
    'Dotar',
    'Educar',
    'Ejecutar',
    'Elegir',
    'Encender',
    'Entender',
    'Entregar',
    'Entregarse',
    'Entrenar',
    'Entretener',
    'Entusiasmar',
    'Escribir',
    'Evaluar',
    'Explorar',
    'Expresar',
    'Extender',
    'Facilitar',
    'Financiar',
    'Fomentar',
    'Generar',
    'Hablar',
    'Hacer',
    'Idear',
    'Identificar',
    'Iluminar',
    'Implementar',
    'Improvisar',
    'Ingeniar',
    'Inspirar',
    'Integrar',
    'Jugar',
    'Lanzar',
    'Liderar',
    'Llamar',
    'Lograr',
    'Madurar',
    'Manejar',
    'Manifestar',
    'Mantener',
    'Mediar',
    'Medir',
    'Modelar',
    'Moldear',
    'Motivar',
    'Mover',
    'Negociar',
    'Nutrir',
    'Ofrecer',
    'Organizar',
    'Otorgar',
    'Participar',
    'Perdonar',
    'Pensar',
    'Permitir',
    'Perseguir',
    'Persuadir',
    'Poseer',
    'Postular',
    'Practicar',
    'Preparar',
    'Presentar',
    'Producir',
    'Progresar',
    'Prometer',
    'Promover',
    'Proveer',
    'Realizar',
    'Recibir',
    'Reclamar',
    'Recordar',
    'Reducir',
    'Refinar',
    'Reflexionar',
    'Reformar',
    'Regresar',
    'Relacionarse',
    'Relajarse',
    'Renovar',
    'Respetar',
    'Restaurar',
    'Reunir',
    'Reunirse',
    'Revisar',
    'Saber',
    'Sacrificar',
    'Sanar',
    'Satisfacer',
    'Servir',
    'Soltar',
    'Soñar',
    'Sostener',
    'Tocar',
    'Tomar',
    'Trabajar',
    'Traducir',
    'Usar',
    'Validar',
    'Valorar',
    'Vender',
    'Verbalizar',
    'Viajar',
    'Vivir',
  ].sort(),
  en: [
    'Hug',
    'Open',
    'Caress',
    'Advise',
    'Adopt',
    'Acquire',
    'Affect',
    'Affirm',
    'Group',
    'Save',
    'Praise',
    'Encourage',
    'Alleviate',
    'Love',
    'Amplify',
    'Support',
    'Ascend',
    'Associate',
    'Fund',
    'Advance',
    'Venture',
    'Bright',
    'Cause',
    'Collect',
    'Command',
    'Combine',
    'Trade',
    'Share',
    'Compete',
    'Complete',
    'Compose',
    'Communicate',
    'Conceive',
    'Connect',
    'Trust',
    'Confirm',
    'Congratulate',
    'Know',
    'Consider',
    'Build',
    'Contact',
    'Continue',
    'Hire',
    'Summon',
    'Create',
    'Believe',
    'Cure',
    'Sing',
    'Give',
    'Decide',
    'Defend',
    'Demonstrate',
    'Discover',
    'Draw',
    'Drive',
    'Argue',
    'Distribute',
    'Provide',
    'Educate',
    'Execute',
    'Choose',
    'Ignite',
    'Understand',
    'Deliver',
    'Surrender',
    'Train',
    'Entertain',
    'Enthuse',
    'Write',
    'Evaluate',
    'Explore',
    'Express',
    'Extend',
    'Facilitate',
    'Fund',
    'Foment',
    'Generate',
    'Talk',
    'Do',
    'Think',
    'Identify',
    'Illuminate',
    'Implement',
    'Improvise',
    'Devise',
    'Inspire',
    'Integrate',
    'Play',
    'Throw',
    'Lead',
    'Call',
    'Reach',
    'Mature',
    'Drive',
    'Manifest',
    'Maintain',
    'Mediate',
    'Measure',
    'Model',
    'Model',
    'Motivate',
    'Move',
    'Negotiate',
    'Feed',
    'Offer',
    'Organize',
    'Participate',
    'Forgive',
    'Allow',
    'Pursue',
    'Persuade',
    'Possess',
    'Postulate',
    'Practice',
    'Prepare',
    'Present',
    'Produce',
    'Thrive',
    'Promise',
    'Promote',
    'Provide',
    'Realize',
    'Make',
    'Receive',
    'Claim',
    'Remember',
    'Reduce',
    'Refine',
    'Think',
    'Reshape',
    'Return',
    'Relate',
    'Relax',
    'Renovate',
    'Respect',
    'Restore',
    'Reunite',
    'Review',
    'Know',
    'Sacrifice',
    'Satisfy',
    'Serve',
    'Release',
    'Dream',
    'Hold',
    'Touch',
    'Take',
    'Work',
    'Translate',
    'Use',
    'Validate',
    'Value',
    'Sell',
    'Verbalize',
    'Travel',
    'Live',
  ].sort(),
});

export const MissionGroups = () =>
  [
    t`Ecology`,
    t`Family`,
    t`Movies`,
    t`Education`,
    t`Media`,
    t`Healthcare`,
    t`Elders`,
    t`Soil`,
    t`Children`,
    t`Poverty`,
    t`Agriculture`,
    t`Meteorology`,
    t`Addictions`,
    t`Companies`,
    t`Mind`,
    t`Sick and disabled persons`,
    t`Human development`,
    t`Space`,
    t`Literature`,
    t`Labor relationships`,
    t`Books`,
    t`Food`,
    t`Management`,
    t`Finances`,
    t`Biotechnology`,
    t`Female issues`,
    t`Landscape`,
    t`Traveling`,
    t`Scale modeling`,
    t`Design`,
    t`Energy`,
    t`Justice`,
    t`Government`,
    t`Fashion`,
    t`Sea`,
    t`Infrastructure`,
    t`Fishing`,
    t`Religion`,
    t`Spirituality`,
    t`Safety`,
    t`Tourism`,
    t`Animals`,
    t`Frontiers`,
    t`Sexuality`,
    t`Arts`,
    t`Sports`,
    t`Technology`,
    t`Defence`,
    t`Manufacturing`,
    t`Mining`,
    t`Water`,
    t`Youth`,
    t`Marketing`,
    t`Male issues`,
    t`Human rights`,
  ].sort();
