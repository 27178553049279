import React from 'react';
import { connect } from 'react-redux';
import MissionVerbAll from './MissionVerbAll';
import MissionVerbMost from './MissionVerbMost';
import { filterMostVerbs } from '../../redux/mission/missionActions';
import { useNavigate, useParams } from 'react-router';

const MissionVerbWizard = ({ allVerbs, dispatchFilterMostVerbs }) => {
  let navigate = useNavigate();
  const { step } = useParams();

  const handleNext = () => {
    dispatchFilterMostVerbs(allVerbs);
    navigate('/mission/verbs/most');
  };

  const handleFinish = () => {
    navigate('/mission/groups/all');
  };

  return (
    <div className="col-md-12">
      {step === 'all' ? (
        <MissionVerbAll onNext={handleNext} />
      ) : (
        <MissionVerbMost onFinish={handleFinish} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allVerbs: state.mission.allVerbs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchFilterMostVerbs: (verbs) => dispatch(filterMostVerbs(verbs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionVerbWizard);
