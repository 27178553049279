import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import LanguageSetter from '../components/LanguageSetter';
import { Helmet } from 'react-helmet';

const HomePage = () => (
  <div>
    <Helmet>
      <title>Misionero</title>
    </Helmet>
    <h2>
      <Trans>Welcome to</Trans>
      <br />
      Misionero
    </h2>
    <Link
      id="find-out-yours"
      className="btn btn-success btn-lg"
      to="/mission/values"
    >
      <Trans>Build your mission</Trans>
    </Link>
    <LanguageSetter />
  </div>
);

export default HomePage;
