import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar collapseOnSelect bg="light" expand="lg">
      <Navbar.Brand>
        <Link to="/" className="navbar-brand">
          Misionero
        </Link>
      </Navbar.Brand>
    </Navbar>
  );
};

export default Header;
