export const MissionActionTypes = {
  SET_VALUES: 'SET_ANSWER',
  SET_ALL_VERBS: 'SET_ALL_VERBS',
  ADD_TO_ALL_VERBS: 'ADD_TO_ALL_VERBS',
  REMOVE_FROM_ALL_VERBS: 'REMOVE_FROM_ALL_VERBS',
  SET_MOST_VERBS: 'SET_MOST_VERBS',
  FILTER_MOST_VERBS: 'FILTER_MOST_VERBS',
  ADD_TO_MOST_VERBS: 'ADD_TO_MOST_VERBS',
  REMOVE_FROM_MOST_VERBS: 'REMOVE_FROM_MOST_VERBS',
  SET_ALL_GROUPS: 'SET_ALL_GROUPS',
  ADD_TO_ALL_GROUPS: 'ADD_TO_ALL_GROUPS',
  REMOVE_FROM_ALL_GROUPS: 'REMOVE_FROM_ALL_GROUPS',
  SET_MOST_GROUPS: 'SET_MOST_GROUPS',
  FILTER_MOST_GROUPS: 'FILTER_MOST_GROUPS',
  ADD_TO_MOST_GROUPS: 'ADD_TO_MOST_GROUPS',
  REMOVE_FROM_MOST_GROUPS: 'REMOVE_FROM_MOST_GROUPS',
  SET_SAVED: 'SET_SAVED',
  SET_LIST: 'SET_LIST',
  SET_MISSION: 'SET_MISSION',
  CLEAN: 'CLEAN',
};

export const setValues = (values) => ({
  type: MissionActionTypes.SET_VALUES,
  values,
});

export const setAllVerbs = (verbs) => ({
  type: MissionActionTypes.SET_ALL_VERBS,
  verbs,
});

export const addToAllVerbs = (verb) => ({
  type: MissionActionTypes.ADD_TO_ALL_VERBS,
  verb,
});

export const removeFromAllVerbs = (verb) => ({
  type: MissionActionTypes.REMOVE_FROM_ALL_VERBS,
  verb,
});

export const setMostVerbs = (verbs) => ({
  type: MissionActionTypes.SET_MOST_VERBS,
  verbs,
});

export const filterMostVerbs = (verbs) => ({
  type: MissionActionTypes.FILTER_MOST_VERBS,
  verbs,
});

export const addToMostVerbs = (verb) => ({
  type: MissionActionTypes.ADD_TO_MOST_VERBS,
  verb,
});

export const removeFromMostVerbs = (verb) => ({
  type: MissionActionTypes.REMOVE_FROM_MOST_VERBS,
  verb,
});

export const setAllGroups = (groups) => ({
  type: MissionActionTypes.SET_ALL_GROUPS,
  groups,
});

export const addToAllGroups = (group) => ({
  type: MissionActionTypes.ADD_TO_ALL_GROUPS,
  group,
});

export const removeFromAllGroups = (group) => ({
  type: MissionActionTypes.REMOVE_FROM_ALL_GROUPS,
  group,
});

export const setMostGroups = (groups) => ({
  type: MissionActionTypes.SET_MOST_GROUPS,
  groups,
});

export const filterMostGroups = (groups) => ({
  type: MissionActionTypes.FILTER_MOST_GROUPS,
  groups,
});

export const addToMostGroups = (group) => ({
  type: MissionActionTypes.ADD_TO_MOST_GROUPS,
  group,
});

export const removeFromMostGroups = (group) => ({
  type: MissionActionTypes.REMOVE_FROM_MOST_GROUPS,
  group,
});

export const setSaved = (status) => ({
  type: MissionActionTypes.SET_SAVED,
  status,
});

export const setList = (list) => ({
  type: MissionActionTypes.SET_LIST,
  list,
});

export const setMission = (mission) => ({
  type: MissionActionTypes.SET_MISSION,
  mission,
});

export const clean = () => ({
  type: MissionActionTypes.CLEAN,
});
